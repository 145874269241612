import { LoadingVariant } from '@aurora/shared-client/components/common/Loading/enums';
import Loading from '@aurora/shared-client/components/common/Loading/Loading';
import type { LoadingVariantTypeAndProps } from '@aurora/shared-client/components/common/Loading/types';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import type { ComponentResponse } from '@aurora/shared-client/components/useCachedComponent';
import useCachedComponent from '@aurora/shared-client/components/useCachedComponent';
import { stripCustomComponentIdPrefix } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type IconTypes from '@aurora/shared-client/icons';
import { LoadingSize, LoadingSpacing } from '@aurora/shared-client/types/enums';
import type { ComponentResolveServerFragment } from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import React, { useEffect } from 'react';

import { CustomComponentRegistry } from '../../common/Widget/WidgetRegistry';
import PageEditorWidgetListItem from '../../pageeditor/PageEditorWidgetListItem/PageEditorWidgetListItem';
import useTranslation from '../../useTranslation';

const log = getLog(module);

interface Props {
  /**
   * The custom component ids to display
   */
  componentIds: Array<string>;
  /**
   * The widget icon
   */
  icon: IconTypes;

  /**
   * @callback
   * Fired when the custom widget is added.
   *
   * @param widgetId the id of the widget to be added.
   * @param name the name of the widget to be added.
   * @param customComponent the props of the widget to be added.
   */
  addWidget(widgetId: string, name: string, customComponent: ComponentResolveServerFragment): void;
}

interface ComponentLookupProps {
  /**
   * The id of the custom component being looked up.
   */
  customComponentId: string;
}

const loadingVariant: LoadingVariantTypeAndProps = {
  type: LoadingVariant.DOT,
  props: {
    size: LoadingSize.SM,
    spacing: LoadingSpacing.SM
  }
};

/**
 * Renders a list of Custom Components based off of the componentIds provided by the {@link Query#componentIds} query
 *
 * @author Vishnu Das, Doug Schroeder, Jonathan Bridges
 */
const CustomComponentLibrary: React.FC<React.PropsWithChildren<Props>> = ({
  componentIds,
  icon,
  addWidget
}) => {
  if (!componentIds || componentIds.length === 0) {
    return null;
  }

  /**
   * Renders each of the above queried custom components as a widget library item.
   *
   * @param widgetId id of the custom component being rendered
   * @param customComponent instance of a custom component with id {@link widgetId}
   */
  function renderWidgetItem(
    widgetId: string,
    customComponent: ComponentResolveServerFragment
  ): React.ReactElement {
    return (
      <PageEditorWidgetListItem
        widgetId={widgetId}
        title={stripCustomComponentIdPrefix(widgetId).replaceAll('_', ' ')}
        description={customComponent.properties.config?.description}
        icon={icon}
        onClick={(): void => {
          addWidget(CustomComponentRegistry.static.widgetId, widgetId, customComponent);
        }}
      />
    );
  }

  const CustomComponentItem: React.FC<React.PropsWithChildren<ComponentLookupProps>> = ({
    customComponentId
  }) => {
    const { addToast } = useToasts();
    const { formatMessage, loading: textLoading } = useTranslation(
      EndUserComponent.CUSTOM_COMPONENT_LIBRARY
    );
    const componentResponse: ComponentResponse = useCachedComponent(customComponentId, false);
    const { loading, data, error } = componentResponse;
    const component = data?.component;
    const notLoadable = !loading && !textLoading && !component;
    useEffect(() => {
      if (notLoadable) {
        addToast({
          toastVariant: ToastVariant.FLYOUT,
          alertVariant: ToastAlertVariant.WARNING,
          title: formatMessage('noComponentForIdErrorTitle'),
          message: formatMessage('noComponentForIdErrorMessage', {
            customComponentId: customComponentId ?? 'null'
          }),
          id: `no-custom-component-for-id-${customComponentId}-error`
        });
      }
    }, [addToast, customComponentId, formatMessage, loading, notLoadable, textLoading]);
    if (error) {
      log.error(
        `Unable to fetch component from cache for component id ${customComponentId}: ${error}`
      );
      return null;
    }
    if (loading || textLoading) {
      return <Loading key={customComponentId} variant={loadingVariant} />;
    }

    if (notLoadable) {
      log.warn(`No component found for component id ${customComponentId}`);
      return null;
    }
    return renderWidgetItem(customComponentId, component);
  };

  return (
    <div data-testid="CustomComponentLibrary">
      {componentIds.map((customComponentId: string) => {
        return (
          customComponentId && (
            <CustomComponentItem key={customComponentId} customComponentId={customComponentId} />
          )
        );
      })}
    </div>
  );
};

export default CustomComponentLibrary;
